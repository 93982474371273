<template>
  <div class="map-container">
    <div id="map" class="map"></div>
    <div class="control-panel">
      <input v-model="searchQuery" placeholder="输入检索关键词" />
      <button @click="searchPOI">检索 POI</button>
      <button @click="startPickGeology">点位地质拾取</button>
      <button @click="removeGeoJsonLayer">删除GeoJSON图层</button>
    </div>
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import axios from 'axios';

export default {
  name: 'LeafletMap',
  data() {
    return {
      map: null,
      drawnItems: null,
      geoJsonSource: null
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      this.map = L.map('map', {
        center: [39.9042, 116.4074],
        zoom: 13,
        attributionControl:false
      });

      // 添加天地图底图
      L.tileLayer('http://t0.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=10e36908782a69691cc52e4ef7feab78', {
        subdomains: ['0', '1', '2', '3', '4', '5', '6', '7'],
        attribution: '© 天地图'
      }).addTo(this.map);

      // // 添加天地图底图
      // L.tileLayer('http://t2.tianditu.gov.cn/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=10e36908782a69691cc52e4ef7feab78', {
      //   subdomains: ['0', '1', '2', '3', '4', '5', '6', '7'],
      //   attribution: '© 天地图'
      // }).addTo(this.map);

      // 添加矢量注记图层
      L.tileLayer('http://t2.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=10e36908782a69691cc52e4ef7feab78', {
        subdomains: ['0', '1', '2', '3', '4', '5', '6', '7'],
        attribution: '© 天地图'
      }).addTo(this.map);


      // =============== 解决点击之后，再缩放地图报错的问题 ===============
      // 重写 Tooltip 的 _animateZoom 方法
      L.Tooltip.prototype._animateZoom = function (e) {
        if (!this._map) return;
        const pos = this._map._latLngToNewLayerPoint(this._latlng, e.zoom, e.center);
        this._setPosition(pos);
      };

      // 重写 Popup 的 _animateZoom 方法
      L.Popup.prototype._animateZoom = function (e) {
        if (!this._map) return;
        const pos = this._map._latLngToNewLayerPoint(this._latlng, e.zoom, e.center);
        const anchor = this._getAnchor();
        L.DomUtil.setPosition(this._container, pos.add(anchor));
      };
      // =============== 解决点击之后，再缩放地图报错的问题 ===============

      // 创建一个 FeatureGroup 来管理绘制的图层
      this.drawnItems = new L.FeatureGroup();
      this.map.addLayer(this.drawnItems);

      // 初始化绘制工具
      this.initDrawControl();
    },
    initDrawControl() {
      const drawControl = new L.Control.Draw({
        position: 'topright',
        draw: {
          polyline: false, // 禁用折线绘制
          polygon: false, // 禁用多边形绘制
          circle: false,
          rectangle: true, // 启用矩形绘制
          marker: false, // 禁用标记绘制
          circlemarker: false // 禁用圆形绘制
        },
        edit: {
          featureGroup: this.drawnItems
        }
      });

      this.map.addControl(drawControl);

      // 监听绘制事件
      this.map.on(L.Draw.Event.CREATED, (e) => {
        const layer = e.layer;
        const bounds = layer.getBounds();
        const boundsData = {
          minLon: bounds.getWest(),
          minLat: bounds.getSouth(),
          maxLon: bounds.getEast(),
          maxLat: bounds.getNorth()
        };

        // 发送 POST 请求
        this.fetchGeoJson(boundsData);
      });

      // 覆盖 _onMouseMove 方法以捕获错误
      const originalOnMouseMove = L.Draw.Rectangle.prototype._onMouseMove;
      L.Draw.Rectangle.prototype._onMouseMove = function (e) {
        try {
          originalOnMouseMove.call(this, e);
        } catch (error) {
          console.error('Error in _onMouseMove:', error);
        }
      };
    },
    async fetchGeoJson(boundsData) {
      try {
        const response = await axios.post('http://123.56.244.27:8086/geotools/getGeologyFileByRectangle', boundsData);
        const geoJsonData = JSON.parse(response.data.body.geojsonFeaturecollection); // 解析 GeoJSON 字符串

        // 清除之前的 GeoJSON 数据
        if (this.geoJsonSource) {
          this.map.removeLayer(this.geoJsonSource);
        }

        // 创建新的 GeoJSON 图层
        this.geoJsonSource = L.geoJSON(geoJsonData, {
          style: {
            color: 'white', // 边界颜色为白色
            weight: 2,      // 边界宽度
            opacity: 1,     // 边界不透明度
            fillColor: 'rgba(0, 0, 255, 0.3)', // 填充颜色，透明度为 0.3
            fillOpacity: 0.3 // 填充透明度
          },
          onEachFeature: (feature, layer) => {
          if (feature.properties && feature.properties.qduecd) {
            layer.bindPopup(`类型: ${feature.properties.qduecd}`);
          }
        }
        }).addTo(this.map);

        this.map.fitBounds(this.geojsonLayer.getBounds());
      } catch (error) {
        console.error('Error fetching GeoJSON data:', error);
      }
    },
    removeGeoJsonLayer() {
      if (this.geoJsonSource) {
        this.map.removeLayer(this.geoJsonSource);
        this.geoJsonSource = null;
      }
    },
    async searchPOI() {
      if (!this.searchQuery) {
        alert('请输入检索关键词');
        return;
      }

      try {
        const response = await axios.get(`http://123.56.244.27:8086/geotools/searchPoi?keyWords=${this.searchQuery}&key=a&pageSize=3`);
        const result = response.data;

        if (result.errcode === 0 && result.body.data.length > 0) {
          // 清除之前的标记
          // this.markers.forEach(marker => this.map.removeLayer(marker));
          this.markers = [];

          // 定义自定义图标样式
          const customIcon = L.icon({
            iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png', // 自定义图标图片
            iconSize: [25, 41], // 图标大小
            iconAnchor: [12, 41], // 图标锚点
            popupAnchor: [1, -34] // 弹出框锚点
          });

          // 遍历返回的 POI 数据
          result.body.data.forEach(poi => {
            const location = poi.location.split(',');
            const latLng = [parseFloat(location[1]), parseFloat(location[0])];

            // 创建标记并添加到地图
            const marker = L.marker(latLng, { icon: customIcon }).addTo(this.map).bindPopup(poi.name);
            this.markers.push(marker);

            // 点击标记时调用接口获取地质类型信息
            marker.on('click', () => {
              this.getGeologyType(latLng[1], latLng[0]);
            });

            // 跳转到第一个 POI 的位置
            if (this.markers.length === 1) {
              this.map.setView(latLng, 15);
            }
          });
        } else {
          alert('未找到匹配的 POI');
        }
      } catch (error) {
        console.error('Error searching POI:', error);
        alert('检索失败，请稍后再试');
      }
    },
    async getGeologyType(lon, lat) {
      try {
        const response = await axios.get(`http://123.56.244.27:8086/geotools/getGeologyType?lon=${lon}&lat=${lat}`);
        const result = response.data;

        if (result.errcode === 0 && result.body) {
          const { qduecd, yshb, mdaec, ysc } = result.body;
          const popupContent = `
            <strong>地质类型信息：</strong><br>
            <p>地层代码: ${qduecd}</p>
            <p>颜色: ${yshb}</p>
            <p>主要矿物成分: ${mdaec}</p>
            <p>岩性代码: ${ysc}</p>
          `;

          // 在地图上显示弹出框
          L.popup()
            .setLatLng([lat, lon])
            .setContent(popupContent)
            .openOn(this.map);
        } else {
          alert('未找到地质类型信息');
        }
      } catch (error) {
        console.error('Error fetching geology type:', error);
        alert('获取地质类型信息失败，请稍后再试');
      }
    },
    startPickGeology() {
      // 切换到地质拾取模式
      this.pickGeologyActive = true;
      this.map.getContainer().style.cursor = 'crosshair';
      this.map.on('click', this.handleMapClick);
    },
    handleMapClick(event) {
      if (!this.pickGeologyActive) return;

      const { lat, lng } = event.latlng;
      this.getGeologyType(lng, lat);
      // this.pickGeologyActive = false; // 关闭地质拾取模式
    }
  },
  beforeUnmount() {
    if (this.map) {
      this.map.remove();
    }
  }
};
</script>

<style scoped>
.map-container {
  width: 100%;
  height: 100vh; /* 确保父容器高度为视口高度 */
  position: relative;
}
.map {
  width: 100%;
  height: 100%; /* 确保地图高度为父容器高度 */
}
.control-panel {
  position: absolute;
  top: 18px;
  left: 50px;
  z-index: 1000;
  background: white;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
}
.control-panel input {
  margin-right: 1px; /* 在输入框和按钮之间添加间隔 */
}.control-panel button {
  margin-right: 10px; /* 在按钮之间添加间隔 */
}
</style>