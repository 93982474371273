<!--
 * @Author: tyz tyz.bnu@gmail.com
 * @Date: 2025-02-20 14:14:16
 * @LastEditors: tyz tyz.bnu@gmail.com
 * @LastEditTime: 2025-02-21 18:32:41
 * @FilePath: \undefinedd:\projects\frontEnd\rectangle\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <LeafletMap />
  </div>
</template>

<script>
import LeafletMap from './components/LeafletMap.vue';

export default {
  name: 'App',
  components: {
    LeafletMap
  },
  mounted() {
    document.title = 'GeologyMine'; // 设置浏览器标签页标题
  }
};
</script>